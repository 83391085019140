<template>
<!-- *********************************************************************************** DESKTOP MENU-->
<header class="hide_on_mobile">
    <div class="row" style="margin:auto;">
        <!-- logo -->
        <div class="logo_desktop">
            <img src="../../assets/images/logo_desktop.png" srcset="../../assets/images/logo_desktop.png 1x, ../../assets/images/logo_desktop_2x.png 2x" alt="Second to None" />
        </div>
    </div>
</header>

<!-- *********************************************************************************** MOBILE MENU-->
<div class="hide_on_desktop header_mobile">
    <div class="logo_mobile">
        <img src="../../assets/images/logo_mobile.png" srcset="../../assets/images/logo_mobile.png 1x, ../../assets/images/logo_mobile_2x.png 2x" alt="CX Group" />
    </div>
</div>

<!-- BIG ORANGE BAR / HEADER -->
<section class="product_header">
    <div class="row" style="margin: auto">
        <div class="tagline">{{ this.$store.getters.getPageTitle }}</div>
    </div>
</section>
</template>

<script>
export default {
    name: 'Layout_NoHeader',
}
</script>
