<template>
<!-- <Layout_Header /> -->
<component :is="layout">
    <slot />
</component>

<section class="app_content">
    <div id="main">
        <div class="main_cont">
            <router-view />
        </div>
    </div>
</section>

<Layout_Footer />
<Layout_MobileSnackbar />
</template>

<script>
import init_fontawesome from "@/modules/init_fontawesome.js";
import Layout_Header from '@/components/layouts/Layout_Header'
import Layout_NoHeader from '@/components/layouts/Layout_NoHeader'
import Layout_Footer from '@/components/layouts/Layout_Footer'
import Layout_MobileSnackbar from '@/components/layouts/Layout_MobileSnackbar'

export default {
    components: {
        Layout_Footer,
        Layout_Header,
        Layout_MobileSnackbar,
    },
    computed: {
        layout() {
            let layoutMeta = this.$route.meta.layout;
            if (layoutMeta == 'Layout_NoHeader') {
                return Layout_NoHeader;
            } else {
                return Layout_Header;
            } 
        }
    },
    methods: {
        async init() {},
    },
    beforeMount() {
        this.init();
    },
    mounted() {
        init_fontawesome();
    }
};
</script>

<style>
@import "./assets/css/style.css";
@import "./assets/css/style_app.css";
@import "./assets/css/style_sh.css";
@import "./assets/css/gallery.css";
@import "./assets/css/zocial.css";
</style>
