<template>
<div id="snackbarApp" v-if="isiOS || isAndroid">
    <div class="snackbarAppClose" v-on:click="closeSnackbar()">&times;</div>&nbsp;&nbsp;
    <div v-if="isiOS" class="snackbarAppText">
        <span class='snackbarAppSpan'>ShopperHub app now available!</span>
        <br /><br />
        <a href='https://shopperhublink.cxgroup.com/c8Ci' v-on:click="closeSnackbar()">
            <img class='snackbarAppImage' src='images/app_stores/ios_app_store.png' />
        </a>
    </div>
    <div v-if="isAndroid" class="snackbarAppText">
        <span class='snackbarAppSpan'>ShopperHub app now available!</span>
        <br /><br />
        <a href='https://shopperhublink.cxgroup.com/c8Ci' v-on:click="closeSnackbar()">
            <img class='snackbarAppImage' src='images/app_stores/google_play.png' />
        </a>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            isiOS: false,
            isAndroid: false,
        };
    },
    methods: {
        async init() {
            this.detectMobile()
                .then(async isMobile => {
                    const snackbarLastDismissed = localStorage.getItem('snackbarLastDismissed');
                    const oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in milliseconds

                    if (!snackbarLastDismissed || Date.now() - snackbarLastDismissed > oneWeek) {
                        if (isMobile == false) {
                            var _os = this.getMobileOperatingSystem();

                            if (_os == "Android") {
                                this.isAndroid = true;
                            } else if (_os == "iOS") {
                                this.isiOS = true;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error("init Error:", error);
                });
        },
        async closeSnackbar() {
            localStorage.setItem('snackbarLastDismissed', Date.now().toString());
            this.isAndroid = false;
            this.isiOS = false;
        },
        detectMobile() {
            return new Promise((resolve, reject) => {
                try {
                    const xmlhttp = new XMLHttpRequest();
                    const key = "KKUAAANXNAAASAWADJAGAWJH3H5SSFFQ7J82C4B796244M33ZRURRSWNYJJLYR6S8GVGDYNURHF74V9YZH94JMA";
                    const ua = window.navigator.userAgent;
                    const url = `https://cloud.51degrees.com/api/v1/${key}/match?user-agent=${ua}&Values=IsMobile+IsTablet`;

                    xmlhttp.onreadystatechange = function () {
                        if (xmlhttp.readyState === 4) {
                            if (xmlhttp.status === 200) {
                                const match = JSON.parse(xmlhttp.responseText);
                                const isMobile = match.Values.IsTablet === 'True' || match.Values.IsMobile === 'True';
                                resolve(isMobile);
                            } else {
                                reject(new Error(`Request failed with status: ${xmlhttp.status}`));
                            }
                        }
                    };

                    xmlhttp.open("GET", url, true);
                    xmlhttp.send();
                } catch (e) {
                    console.error("detectMobile Error:", e);
                    reject(e);
                }
            });
        },
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },
    },
    mounted() {
        this.init();
    },
};
</script>
