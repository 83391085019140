export function initFacebookSdk() {
    // load facebook sdk script
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));  

    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = function () {
            FB.init({ // eslint-disable-line no-undef
                appId: '1718590075084121',
                cookie: true,
                xfbml: true,
                version: 'v12.0',
                //status: true,
                scope: 'email' // Make sure 'email' scope is included
            });

            resolve();
        };
    });
}