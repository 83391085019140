export default function init_fontawesome() {
    if(document.querySelector("script[src='https://kit.fontawesome.com/bbd8419e97.js']")){ return; }
    else {
        const scriptA = document.createElement('script');
        scriptA.async = true;
        scriptA.defer = true;
        scriptA.src="https://kit.fontawesome.com/bbd8419e97.js";
        document.querySelector('head').appendChild(scriptA);
    }
}
